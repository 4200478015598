import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CardError from "components/Card/CardError";
import { ApimPostPromise } from "components/Common/ApimSender";
import Button from "components/CustomButtons/Button";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useMemo, useState } from "react";
import { HasRight } from "services/user/UserHelper";
import { StructureUe } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "tools";
import RequestProperties from "./RequestProperties";
import RequestScope from "./RequestScope";
import RequestSupplierCoupa from "./RequestSupplierCoupa";
import RequestSupplierThirdParty from "./RequestSupplierThirdParty";
import RequesterInformation from "./RequesterInformation";
import { History } from "@material-ui/icons";
import WorkflowHistory from "../WorkflowHistory";
import RequestSupplierPayChar from "./RequestSupplierPayChar";
function RequestSupplierCreation({
  workflow,
  saveDraft,
  deleteDraft,
  validateWorkflow,
  abortWorkflow,
  closeWorkflow,
  errors,
  masterValues,
  currentUser,
  classes
}) {
  const [state, setState] = useState({ workflow: workflow, errors, isLoading: false });
  const [dialogBox, setDialogBox] = useState(null);
  var [openHisto, setOpenHisto] = useState(false);

  const request = useMemo(() => state.workflow?.workflowSupplier, [state]);

  const availableUes = useMemo(() => {
    var uesForPurchaserWorkflows = currentUser.structuresPurchaser.ues;
    if (state.workflow.workflowStatusCode !== "Started" && state.workflow.workflowStatusCode !== "Aborted") {
      // Cas de l'admin
      return masterValues[StructureUe];
    }
    return uesForPurchaserWorkflows ?? [];
  }, [currentUser]);

  const defaultLang = useMemo(() => currentUser.language, [currentUser]);

  var isReadOnly = useMemo(() => {
    if (
      workflow.workflowStatusCode === "Started" ||
      (workflow.workflowStatusCode === "Aborted" && workflow.creationUser === currentUser.identifier) ||
      HasRight("thirdparty_workflow.edit")
    )
      return false;
    return true;
  }, [workflow]);

  const closeDetail = isFromButton => {
    if (!isFromButton) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeWorkflow() });
    } else {
      closeWorkflow();
    }
  };

  const areDifferent = (str1, str2) => {
    if (isNullOrEmpty(str1) && isNullOrEmpty(str2)) return false;
    if (isNullOrEmpty(str1) || isNullOrEmpty(str2)) return true;
    return str1 !== str2;
  };

  const setRequest = workflow => {
    var previousSupplierRequest = state.workflow?.workflowSupplier;
    var supplierRequest = workflow.workflowSupplier;
    var computeApprovers = false;
    var isCoupaEditable = IsCoupaEditable(isReadOnly, workflow, supplierRequest, currentUser);
    supplierRequest.supplierCoupaChanged = isCoupaEditable;
    var isPayCharEditable = IsPayCharEditable(isReadOnly, workflow, supplierRequest, currentUser);

    if (
      areDifferent(supplierRequest.applicantUe, previousSupplierRequest.applicantUe) ||
      (supplierRequest.contextCode === "consultation" &&
        (areDifferent(previousSupplierRequest.requestDomainCode, supplierRequest.requestDomainCode) ||
          areDifferent(previousSupplierRequest.coupaContentGroup, supplierRequest.coupaContentGroup))) ||
      supplierRequest.contextCode !== previousSupplierRequest.contextCode
    ) {
      supplierRequest.coupaContentGroup = null;
      supplierRequest.validators = null;
      supplierRequest.validatorUsers = null;
    }

    if (
      supplierRequest.applicantUe &&
      ((supplierRequest.contextCode === "consultation" && supplierRequest.requestDomainCode) || supplierRequest.contextCode === "advancePurchase") &&
      !supplierRequest.validators
    ) {
      computeApprovers = true;
    }

    if (!computeApprovers) {
      setState({
        ...state,
        isLoading: false,
        workflow: {
          ...workflow,
          isCoupaEditable: !!isCoupaEditable,
          workflowSupplier: supplierRequest,
          isPayCharEditable: !!isPayCharEditable
        }
      });
    } else {
      ApimPostPromise("api/TpCopernic/Suppliers/WorkflowApprovers", {
        applicantUe: supplierRequest.applicantUe,
        domainCode: supplierRequest.requestDomainCode,
        contextCode: supplierRequest.contextCode === "advancePurchase" ? "AdvancePurchase" : "Consultation"
      }).then(response => {
        supplierRequest.validators = response.approvers.map(a => a.login);
        supplierRequest.validatorUsers = response.approvers;
        supplierRequest.coupaContentGroup = response.groupName;
        supplierRequest.coupaContentGroupIdentifier = response.groupIdentifier;
        setState({
          ...state,
          isLoading: false,
          workflow: {
            ...workflow,
            isCoupaEditable: !!isCoupaEditable,
            workflowSupplier: supplierRequest,
            isPayCharEditable: !!isPayCharEditable
          }
        });
      });
    }
  };

  const validate = () => {
    if (state.isLoading) return;

    setState({ ...state, isLoading: true });
    if (state.workflow.workflowStatusCode === "Pending") {
      setDialogBox({
        canDismiss: false,
        type: "okCancel",
        textbox: { title: <Trans>Comment</Trans>, rows: 2 },
        ok: text => {
          validateWorkflow({ ...state.workflow, comment: text });
          setDialogBox(null);
        }
      });
    } else {
      validateWorkflow(state.workflow);
    }
  };

  const abort = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        abortWorkflow({ ...state.workflow, comment: text });
        setDialogBox(null);
      }
    });
  };

  const IsCoupaEditable = (isReadOnly, workflow, item, currentUser) => {
    if (HasRight("thirdparty_workflow.edit")) return true;
    if (isReadOnly) return false;

    if (workflow.workflowStatusCode === "Pending") {
      if (HasRight("thirdparty_supplier.edit")) return true;
      if (!!item.supplierCoupaChanged && HasRight("thirdparty_workflow.edit")) return true;
    }

    if (
      workflow.workflowStatusCode === "Started" &&
      item.supplierCoupa &&
      (!!item.supplierCoupa.isNew ||
        (isNullOrEmpty(item.supplierCoupa.contact?.firstName) ||
          isNullOrEmpty(item.supplierCoupa.contact?.lastName) ||
          !item.supplierCoupa.contact?.details?.some(d => d.contactDetailTypeCode === "MAIL" && !isNullOrEmpty(d.value)) ||
          isArrayNullOrEmpty(item.supplierCoupa.emailBuyer)))
    )
      return true;

    return false;
  };

  const IsPayCharEditable = (isReadOnly, workflow, item) => {
    if (workflow.workflowStatusCode === "Finished") return false;
    if (HasRight("thirdparty_workflow.edit")) return true;
    if (isReadOnly) return false;

    if (workflow.workflowStatusCode === "Pending") {
      if (HasRight("thirdparty_supplier.edit")) return true;
      if (HasRight("thirdparty_workflow.edit")) return true;
    }

    return false;
  };

  // on first load, set the supplierRequest (will compute the coupaContentGroup and validators if needed)
  useEffect(() => {
    setRequest(workflow);
  }, [workflow]);

  useEffect(() => {
    setState({ ...state, errors: errors });
  }, [errors]);

  const badgeColor = useMemo(() => {
    if (state.workflow.workflowStatusCode === "Pending") {
      return "#ff9800";
    } else if (state.workflow.workflowStatusCode === "Started") {
      return "#00acc1";
    } else if (state.workflow.workflowStatusCode === "Aborted") {
      return "#f44336";
    } else if (state.workflow.workflowStatusCode === "Finished") {
      return "#4caf50";
    }
    return "#000000";
  }, [state.workflow.workflowStatusCode]);

  const badgeTitle = useMemo(() => {
    return masterValues["WorkflowStatus"].find(w => w.code === state.workflow.workflowStatusCode)?.label ?? state.workflow.workflowStatusCode;
  }, [state.workflow.workflowStatusCode]);

  let dialogContent;
  const errorCritical = errors?.find(e => e.severity === "Critical");
  if (errorCritical) dialogContent = <CardError error={errorCritical} />;
  else if (state.errorNotFound)
    dialogContent = (
      <div>
        <Trans>WF_SupplierRequest_NotFound</Trans>
      </div>
    );
  else if (!state.workflow || state.isLoading) dialogContent = <CircularProgress />;
  else
    dialogContent = (
      <>
        <RequesterInformation
          availableUes={availableUes}
          request={request}
          setRequest={req => {
            setRequest({ ...state.workflow, workflowSupplier: req });
          }}
          isReadOnly={isReadOnly}
          errors={errors}
        />
        <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gap: 15 }}>
          <div style={{ gridColumn: "1 / span 5" }}>
            <RequestSupplierThirdParty
              request={request}
              setRequest={req => {
                setRequest({ ...state.workflow, workflowSupplier: req });
              }}
              isReadOnly={isReadOnly}
              errors={errors}
            />
            <RequestSupplierPayChar
              show={
                request.linkedThirdParty?.identifier === 0 ||
                !(request.linkedThirdParty?.isSupplier ?? false) ||
                (request.payChar && state.workflow.workflowStatusCode !== "Started")
              }
              thirdParty={request.linkedThirdParty}
              isEditable={state.workflow.isPayCharEditable}
              payChar={{ ...request.payChar, thirdPartyContact: request.payCharContact }}
              setPayChar={c => {
                const { thirdPartyContact, ...payChar } = c;
                setRequest({ ...state.workflow, workflowSupplier: { ...request, payChar: payChar, payCharContact: thirdPartyContact } });
              }}
              defaultLang={defaultLang}
              masterValues={masterValues}
              errors={errors}
            />
            <RequestSupplierCoupa
              show={request.contextCode !== "advancePurchase"}
              thirdParty={request.linkedThirdParty}
              isEditable={state.workflow.isCoupaEditable}
              coupa={request.supplierCoupa}
              setCoupa={c => {
                setRequest({ ...state.workflow, workflowSupplier: { ...request, supplierCoupa: c } });
              }}
              defaultLang={defaultLang}
              masterValues={masterValues}
              errors={errors}
            />
          </div>
          <div style={{ gridColumn: "6 / span 7" }}>
            <RequestScope
              request={request}
              setRequest={req => {
                setRequest({ ...state.workflow, workflowSupplier: req });
              }}
              errors={errors}
              isReadOnly={isReadOnly}
              defaultLang={defaultLang}
            />
            <RequestProperties
              workflow={workflow}
              request={request}
              setRequest={req => {
                setRequest({ ...state.workflow, workflowSupplier: req });
              }}
              errors={errors}
              isReadOnly={isReadOnly}
              currentUser={currentUser}
            />
          </div>
        </div>
      </>
    );

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(workflow)}
        onClose={() => closeDetail(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <div>
            <span
              style={{
                border: `1px solid ${badgeColor}`,
                borderRadius: 50,
                padding: "5px 10px",
                marginRight: 15,
                backgroundColor: "#FFFFFF",
                color: badgeColor,
                fontSize: "0.8rem"
              }}
            >
              {badgeTitle}
            </span>
            {state.workflow.label}
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {(state.workflow.workflowStatusCode === "Started" || state.workflow.workflowStatusCode === "Pending") &&
            (!isReadOnly || HasRight("thirdparty_workflow.edit")) && (
              <>
                {state.isLoading && <CircularProgress />}
                {!state.isLoading && (
                  <Button
                    color="success"
                    onClick={e => {
                      e.currentTarget.disabled = true;
                      validate();
                    }}
                  >
                    <Trans>Validate</Trans>
                  </Button>
                )}
              </>
            )}
          {state.workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit") && (
            <>
              {state.isLoading && <CircularProgress />}
              {!state.isLoading && (
                <Button color="danger" onClick={abort} disabled={state.isLoading}>
                  <Trans>WF_Abort</Trans>
                </Button>
              )}
            </>
          )}
          {state.workflow.identifier > 0 && (
            <Tooltip title={<Trans>History</Trans>} placement="bottom">
              <span>
                <Button simple color="info" onClick={() => setOpenHisto(true)}>
                  <History />
                </Button>
              </span>
            </Tooltip>
          )}
          <div style={{ flex: "1 0 0" }}></div>
          {(state.workflow.workflowStatusCode !== "Started" || !state.workflow.identifier || state.workflow.identifier === 0) && (
            <Button simple color="info" onClick={() => saveDraft(state.workflow)}>
              <Trans>SaveAsDraft</Trans>
            </Button>
          )}
          {state.workflow.workflowStatusCode === "Started" && state.workflow.identifier > 0 && (
            <Button color="error" onClick={() => deleteDraft()}>
              <Trans>deleteDraft</Trans>
            </Button>
          )}
          <Button onClick={() => closeDetail(true)} color={!state.hasChanged ? "info" : ""}>
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
      {!!openHisto && (
        <Dialog open onClose={() => setOpenHisto(false)} fullWidth={true} maxWidth="md">
          <DialogContent>
            <WorkflowHistory workflowId={state.workflow.identifier} defaultLang={defaultLang} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHisto(false)} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(RequestSupplierCreation);
